import React from 'react';
import {useState, useContext, useEffect} from 'react';
import { Container,Spinner, Input, FormGroup, Button } from 'reactstrap';
import { AppContext } from './AppContext';
import {Tabla} from './Tabla';
import {Trabajador} from './Trabajador';
import {Consulta} from './Consulta';
import Swal from 'sweetalert2';

export const Trabajadores = () =>{
    const edoGlobal = useContext(AppContext);
    let [objetos,setObjetos] = useState([]);
    let [unidades,setUnidades] = useState([]);
    let [puestos,setPuestos] = useState([]);
    let [tipos,setTipos] = useState([]);
    let [idObj,setIdObj] = useState(-1);
    let [nombre,setNombre] = useState("");
    let [apePat,setApePat] = useState("");
    let [apeMat,setApeMat] = useState("");
    let [unidad, setUnidad] = useState(0);
    let [tipo, setTipo] = useState(0);
    let [cant,setCant] = useState(0);
    const [cargando,setCargando] = useState(false);
    const [editando,setEditando] = useState(false);
    const [consultando,setConsultando] = useState(false);
    
    useEffect(() => {
        (async () => {
            setCargando(true);
            let url = `${edoGlobal.urlws}/trabajador/unidades`;
            let resp = await fetch(url);
            const unis = await resp.json();
            url = `${edoGlobal.urlws}/trabajador/puestos`;
            resp = await fetch(url);
            const ptos = await resp.json();
            url = `${edoGlobal.urlws}/trabajador/tipos`;
            resp = await fetch(url);
            const tipos = await resp.json();
            setUnidades(unis);
            setPuestos(ptos);
            setTipos(tipos);
            setCargando(false);
        })();
    }, []);

    async function cargarObjetos(){
        setCargando(true);
        let url = `${edoGlobal.urlws}/trabajador/lista?apePat=${apePat}&apeMat=${apeMat}&nombre=${nombre}&unidad=${unidad}&tipo=${tipo}`;
        let resp = await fetch(url);
        const objetos = await resp.json();
        setCargando(false);
        setObjetos(objetos);
        setCant(objetos.length);
    }
    async function nuevoObjeto(){
        const { value: r } = await Swal.fire({
            title: 'Ingrese No. de empleado',
            input: 'text',
            inputLabel: 'No. de empleado',
            inputValue: '',
            showCancelButton: true,
            inputValidator: (value) => {
              if (!Number.isInteger(Number(value))) {
                return 'Debe indicar el numero del empleado';
              }
            }
          })
          
        if (r) {
            setIdObj(Number(r));
            setEditando(true);
        }
    } 
    const editar = i =>{
        setIdObj(objetos[i].I);
        setEditando(true);
    }
    const eliminar = i =>{
        //setIdObj(objetos[i].R);
    }
    const consultar = i =>{
        setIdObj(objetos[i].I);
        setConsultando(true);
    }
    const cerrarFormulario = (cargar) =>{
        setEditando(false);
        if(cargar)
           cargarObjetos();
    }
    const estilo1 = { display: editando || consultando ? 'none' : 'inline' };
    const estilo2 = { display: editando ? 'inline' : 'none' };
    const usu = edoGlobal.hasOwnProperty('usuario') ? edoGlobal.usuario.Id : 0;
    return(
        <div>
            <div style={estilo2}><Trabajador idTrab={idObj} usuario={usu} unidades={unidades} puestos={puestos} tipos={tipos} cerrar={cerrarFormulario} /></div>
            <Container style={estilo1}>
                <div className="row">
                    <div className="col-2">
                        <FormGroup  >
                            <span>Apellido Paterno</span>
                            <Input size="sm" type="text" value={apePat} onChange={e => setApePat(e.target.value)} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup  >
                            <span>Apellido Materno</span>
                            <Input size="sm" type="text" value={apeMat} onChange={e => setApeMat(e.target.value)} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup  >
                            <span>Nombre</span>
                            <Input size="sm" type="text" value={nombre} onChange={e => setNombre(e.target.value)} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup  >
                            <span>Unidad</span>
                            <Input type="select" size="sm" value={unidad} onChange={e => setUnidad(e.target.value)}>
                                <option value={0}>Todas</option>
                                {unidades.map((o, i) => <option value={o.I}>{o.N}</option>)}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup  >
                            <span>Tipo</span>
                            <Input type="select" size="sm" value={tipo} onChange={e => setTipo(e.target.value)}>
                                <option value={0}>Todos</option>
                                {tipos.map((o, i) => <option value={o.I}>{o.N}</option>)}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-1">
                        <br />
                        <Button outline color="success" size="sm" onClick={cargarObjetos}><img src="imagenes/zoom.png" alt="buscar" /></Button>
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        <Button outline color="success" size="sm" onClick={nuevoObjeto}><img src="imagenes/add.png" alt="nuevo" /></Button>
                    </div>
                    <div className='col'>
                        <br/>
                        <span>&nbsp;&nbsp;&nbsp;Trabajadores : <b>{cant}</b></span>
                    </div>
                </div>
                <Tabla campos={["P", "M", "N"]}
                    cols={["Apellido Paterno", "Apellido Materno", "Nombre"]}
                    tams={[150, 150, 150, 30, 30]}
                    datos={objetos}
                    opciones={[{ tit: 'editar', img: 'edit', fn: editar },
                    { tit: 'eliminar', img: 'menos', fn: eliminar }
                    ]}
                />
            </Container>
            {cargando ?
                <div className="loader">
                    <Spinner animation="border" color="warning" />
                </div>
                :
                null
            }
        </div>
    )
}