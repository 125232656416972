import React from 'react';
import { Input } from 'reactstrap';
import { Card, Button,CardBody } from 'reactstrap';
import Swal from 'sweetalert2'
import { AppContext } from './AppContext';
import { Panel } from './Panel';
export default class Login extends React.Component{
    static contextType = AppContext;
    constructor(props){
        super(props);
        this.state = {
            usu:'',
            pwd:''
        };
    }
    componentDidMount() {
        const edoGlobal = this.context;
        if(edoGlobal.urlws === 'https://localhost:44384/'){
            this.state.usu = '303';
            this.state.pwd = 'abc123';
        }
    }
    onChange = (e) =>{
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    loguear  = async() => {
        const edoGlobal = this.context;
        const usu = this.state.usu;
        const pwd = this.state.pwd;
        const url = `${edoGlobal.urlws}/trabajador/loguear?emp=${usu}&pwd=${pwd}`;
        const req = await fetch(url);
        const res = await req.json();
        if(res.Id === 0){
            Swal.fire('Atencion','Datos incorrectos','error');
            return;
        }
        this.props.iniciar(res);
    }
    render(){
        const edoGlobal = this.context;
        return(
            <Panel titulo={edoGlobal.nombreEmpresa}>
                <form >
                    <div className="row">
                        <div className="col">
                            <div className="form-group" size="sm">
                                <span>No. Empleado</span>
                                <input size="sm" className="form-control form-control-sm" type="text" name="usu" value={this.state.usu} onChange={this.onChange} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group" size="sm">
                                <span>Contraseña</span>
                                <Input size="sm" type="password" name="pwd" value={this.state.pwd} onChange={this.onChange} />
                            </div>
                        </div>
                    </div>
                    <Button color="primary" size="sm" outline onClick={this.loguear}>Iniciar sesion</Button>
                </form>
            </Panel>
        )
    }
}