import React from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { AppContext } from './AppContext';

export default class Menu extends React.Component {
  static contextType = AppContext;
    render() {
      const edoGlobal = this.context;
      let nomUsu = '';
      if(edoGlobal.hasOwnProperty('usuario'))
         nomUsu = edoGlobal.usuario.Nombre;

        let modulos = [
                       {id:"prestamos", nombre:"Prestamos"},
                       {id:"consultas", nombre:"Consultas"},
                       {id:"perfil", nombre:"Perfil"},
                    ];
                    
        let config = {
          id:"conf", 
          nombre:"Configuracion", 
          opciones: [
            {id:'confUsuarios',nombre:'Usuarios'}
          ]
        };
    
        return (
          <>
            <ul className="nav">
                {modulos.map((e,i) =>{
                    return(
                        <li key={i} className="nav-item liMenu">
                            <Modulo modulo={e} opcionSeleccionada={this.props.opcionSeleccionada}/>
                        </li>    
                    )
                })}
                <ModuloSimple modulo={config} opcionSeleccionada={this.props.opcionSeleccionada}/>
            </ul>
          </>
            )
    }
}

class Modulo extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false
    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }
  onClick = (e) =>{
      this.toggle();
      //alert(e.target.dataset.opc);
      this.props.opcionSeleccionada(e.target.dataset.opc,e.target.innerText);
  }
  render() {
    const {modulo} = this.props;
    const titulo = modulo.nombre;
    const idMod = modulo.id;
    const catalogos = modulo.catalogos;
    const movimientos = modulo.movimientos;
    const consultas = modulo.consultas;
    const reportes = modulo.reportes;
    return (
      <div>
          <a  id={idMod} onClick={()=>this.props.opcionSeleccionada(idMod,titulo)} className="nav-link opcion" href="#">
          {titulo}
        </a>
      </div>
    );
  }
}

class ModuloSimple extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false
    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }
  onClick = (e) =>{
      this.toggle();
      //alert(e.target.dataset.opc);
      this.props.opcionSeleccionada(e.target.dataset.opc,e.target.innerText);
  }
  render() {
    const {modulo} = this.props;
    const titulo = modulo.nombre;
    const idMod = modulo.id;
    const opciones = modulo.opciones;
    return (
      <div>
          <a  id={idMod} className="nav-link opcion" href="#">
          {titulo}
        </a>
        <Popover placement="bottom" isOpen={this.state.popoverOpen} target={idMod} toggle={this.toggle} trigger="legacy" style={{width:400}}>
          <PopoverBody >
              <div className="container" style={{padding:1}}>
                <div className="colorFondo" style={{padding:5}}>
                  <div className="row">
                      <div className="col">
                        <ul>
                            {opciones.map((e,i) => <li data-opc={e.id} key={i} onClick={this.onClick}>{e.nombre}</li>)}
                        </ul>
                      </div>
                  </div>
                  </div>
              </div>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}