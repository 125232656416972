import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { InputGroupText,InputGroup, Spinner, Input, FormGroup, Button } from 'reactstrap';
import Swal from 'sweetalert2'
import { AppContext } from './AppContext';
import { Panel } from './Panel';

export const Trabajador = (props) => {
    const edoGlobal = useContext(AppContext);
    let [nuevo, setNuevo] = useState(false);
    let [Id, setId] = useState(0);
    let [RFC, setRfc] = useState("");
    let [Nombres, setNombres] = useState("");
    let [ApePat, setApePat] = useState("");
    let [ApeMat, setApeMat] = useState("");
    let [Sexo, setSexo] = useState("");
    let [Edad, setEdad] = useState(0);
    let [Unidad, setUnidad] = useState(-1);
    let [Tipo, setTipo] = useState(-1);
    let [PuestoSindicato, setPuestoSindicato] = useState(-1);
    let [Puesto, setPuesto] = useState("");
    let [Email, setEmail] = useState("");
    let [FechaNac, setFechaNac] = useState("");
    let [Telefono, setTelefono] = useState("");
    let [Curp, setCurp] = useState("");
    let [TipoSangre, setTipoSangre] = useState("");
    let [Categoria, setCategoria] = useState("A");
    let [FechaIng, setFechaIng] = useState("");
    let [FechaIngNom, setFechaIngNom] = useState("");
    let [Antiguedad, setAntiguedad] = useState(0);
    let [AntiguedadNom, setAntiguedadNom] = useState(0);
    let [Usuario, setUsuario] = useState(0);
    let [cargando, setCargando] = useState(false);

    useEffect(() => {
        (async () => {
            setCargando(true);
            let url = `${edoGlobal.urlws}/trabajador/cargar/${props.idTrab}`;
            let resp = await fetch(url);
            const o = await resp.json();
            setCargando(false);
            setId(o.Id);
            setRfc(o.RFC);
            setNombres(o.Nombres);
            setApePat(o.ApePat);
            setApeMat(o.ApeMat);
            setSexo(o.Sexo);
            setEdad(o.Edad);
            setPuesto(o.Puesto);
            setCurp(o.Curp);
            setTipoSangre(o.TipoSangre);
            setUnidad(o.Unidad);
            setPuestoSindicato(o.PuestoSindicato);
            setTipo(o.Tipo);
            setTelefono(o.Telefono);
            setEmail(o.Email);
            setCategoria(o.Categoria);
            setFechaIng(o.FechaIng);
            setAntiguedad(o.Antiguedad);
            setFechaIngNom(o.FechaIngNom);
            setAntiguedadNom(o.AntiguedadNom);
            setUsuario(o.Usuario);
            setNuevo(false);
            if (o.Id == 0) {
                setId(props.idTrab);
                setTipo(-1);
                setUnidad(-1);
                setPuestoSindicato(-1);
                setUsuario(props.usuario);
                setNuevo(true);
            }
        })();
    }, [props.idTrab]);

    async function calcularAnt() {
        let url = `${edoGlobal.urlws}/trabajador/calcularEdad/${FechaIng}`;
        let resp = await fetch(url);
        let edad = await resp.text();
        setAntiguedad(Number(edad));
        if(nuevo)
            setFechaIngNom(FechaIng);
    }
    async function calcularAntNom() {
        let url = `${edoGlobal.urlws}/trabajador/calcularEdad/${FechaIngNom}`;
        let resp = await fetch(url);
        let edad = await resp.text();
        setAntiguedadNom(Number(edad));
    }
    async function calcularEdad() {
        let url = `${edoGlobal.urlws}/trabajador/edad/${RFC}`;
        let resp = await fetch(url);
        let edad = await resp.text();
        setEdad(Number(edad));
    }
    async function guardar() {
        let Activo = true;
        let FechaReg = "";
        let Pwd = "";
        if (ApePat.length < 4) {
            Swal.fire('Atencion', 'Debe indicar el apellido paterno', 'error');
            return;
        }
        if (Nombres.length < 3) {
            Swal.fire('Atencion', 'Debe indicar el nombre', 'error');
            return;
        }
        if (Tipo < 0) {
            Swal.fire('Atencion', 'Debe indicar el tipo de trabajador', 'error');
            return;
        }
        if (Unidad < 0) {
            Swal.fire('Atencion', 'Debe indicar la unidad del trabajador', 'error');
            return;
        }
        if (PuestoSindicato < 0) {
            Swal.fire('Atencion', 'Debe indicar el puesto en el sindicato', 'error');
            return;
        }
        if (Sexo === "") {
            Swal.fire('Atencion', 'Debe indicar el sexo del trabajador', 'error');
            return;
        }
        const dato = {
            Id, Activo, Pwd, Unidad, RFC, Usuario, Curp, Categoria,
            ApePat, ApeMat, Nombres, Sexo, PuestoSindicato, FechaIng, FechaIngNom,
            Email, Telefono, Puesto, Tipo, TipoSangre, FechaReg
        }
        let url = `${edoGlobal.urlws}/trabajador/guardar`;
        setCargando(true);
        const json = JSON.stringify(dato);
        const resp = await fetch(url, {
            method: 'POST', // or 'PUT'
            body: json // data can be `string` or {object}!
        });
        const res = await resp.text();
        setCargando(false);
        if (res === "ok") {
            Swal.fire('Atencion', 'Datos guardados', 'success');
            props.cerrar(true);
        }
        else
            Swal.fire('Atencion', res, 'error');
    }
    function cancelar() {
        props.cerrar(false);
    }
    const tit = "Trabajador " + Id;
    const img = "/content/fotos/" + Id + ".png";
    return (
        <div>
        <Panel titulo={tit}>
            <div >
                <div className='row'>
                    <div className="col-2">
                        <FormGroup >
                            <span>R.F.C. / Edad</span>
                            <InputGroup>
                                <Input size="sm" value={RFC} onChange={e => setRfc(e.target.value)} onBlur={() => calcularEdad()} />
                                <InputGroupText>{Edad}</InputGroupText>
                            </InputGroup>
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup  >
                            <span>Apellido Paterno</span>
                            <Input size="sm" type="text" value={ApePat} onChange={e => setApePat(e.target.value)} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup  >
                            <span>Apellido Materno</span>
                            <Input size="sm" type="text" value={ApeMat} onChange={e => setApeMat(e.target.value)} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup  >
                            <span>Nombre</span>
                            <Input size="sm" type="text" value={Nombres} onChange={e => setNombres(e.target.value)} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup  >
                            <span>Sexo</span>
                            <Input type="select" size="sm" value={Sexo} onChange={e => setSexo(e.target.value)}>
                                <option value="">Especifique</option>
                                <option value="M">Mujer</option>
                                <option value="H">Hombre</option>
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-1">
                        <FormGroup  >
                            <span>Tipo Sangre</span>
                            <Input size="sm" type="text" value={TipoSangre} onChange={e => setTipoSangre(e.target.value)} />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <FormGroup  >
                            <span>Unidad</span>
                            <Input type="select" size="sm" value={Unidad} onChange={e => setUnidad(e.target.value)}>
                                <option value={-1}>Especifique</option>
                                {props.unidades.map((o, i) => <option value={o.I}>{o.N}</option>)}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup  >
                            <span>Tipo</span>
                            <Input type="select" size="sm" value={Tipo} onChange={e => setTipo(e.target.value)}>
                                <option value={-1}>Especifique</option>
                                {props.tipos.map((o, i) => <option value={o.I}>{o.N}</option>)}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup  >
                            <span>Puesto sindicato</span>
                            <Input type="select" size="sm" value={PuestoSindicato} onChange={e => setPuestoSindicato(e.target.value)}>
                                <option value={-1}>Especifique</option>
                                {props.puestos.map((o, i) => <option value={o.I}>{o.N}</option>)}
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup  >
                            <span>C.U.R.P.</span>
                            <Input size="sm" type="text" value={Curp} onChange={e => setCurp(e.target.value)} />
                        </FormGroup>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-2">
                    <FormGroup >
                    <span>Ingreso / Antigüedad</span>
                        <InputGroup>
                            <Input size="sm" type="date" value={FechaIng} onChange={e => setFechaIng(e.target.value)} onBlur={() => calcularAnt()} />
                            <InputGroupText>{Antiguedad}</InputGroupText>
                        </InputGroup>
                        </FormGroup>
                    </div>
                    <div className="col-2">
                    <FormGroup >
                    <span>Ingreso (Nomina) / Antigüedad</span>
                        <InputGroup>
                            <Input size="sm" type="date" value={FechaIngNom} onChange={e => setFechaIngNom(e.target.value)} onBlur={() => calcularAntNom()} />
                            <InputGroupText>{AntiguedadNom}</InputGroupText>
                        </InputGroup>
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup  >
                            <span>Puesto</span>
                            <Input size="sm" type="text" value={Puesto} onChange={e => setPuesto(e.target.value)} />
                        </FormGroup>
                    </div>
                    <div className="col-1">
                        <FormGroup  >
                            <span>Cat.</span>
                            <Input size="sm" type="text" value={Categoria} onChange={e => setCategoria(e.target.value)} />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup  >
                            <span>Email</span>
                            <Input size="sm" type="text" value={Email} onChange={e => setEmail(e.target.value)} />
                        </FormGroup>
                    </div>
                    <div className="col-2">
                        <FormGroup  >
                            <span>Telefono</span>
                            <Input size="sm" type="text" value={Telefono} onChange={e => setTelefono(e.target.value)} />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <br /><br /><br />
            <Button onClick={guardar} size="sm" outline color="success">Guardar</Button>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <Button onClick={cancelar} size="sm" outline color="danger">Cancelar</Button>
            {cargando ?
                <div className="loader">
                    <Spinner animation="border" color="warning" />
                </div>
                :
                null
            }
        </Panel>
        <br/>
        <img src={img} alt={Id} style={{left:'50%'}} />
        </div>
    )
}